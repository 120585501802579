
import './_version.js';
/**
 * Sends a data object to a service worker via `postMessage` and resolves with
 * a response (if any).
 *
 * A response can be set in a message handler in the service worker by
 * calling `event.ports[0].postMessage(...)`, which will resolve the promise
 * returned by `messageSW()`. If no response is set, the promise will not
 * resolve.
 *
 * @param {ServiceWorker} sw The service worker to send the message to.
 * @param {Object} data An object to send to the service worker.
 * @return {Promise<Object|undefined>}
 * @memberof workbox-window
 */
// Better not change type of data.
// eslint-disable-next-line @typescript-eslint/ban-types
function messageSW(sw, data) {
    return new Promise((resolve) => {
        const messageChannel = new MessageChannel();
        messageChannel.port1.onmessage = (event) => {
            resolve(event.data);
        };
        sw.postMessage(data, [messageChannel.port2]);
    });
}
export { messageSW };
